<template>
  <form>
    <div class="vx-row">
      <div class="vx-col mb-5 w-full">
        <label class="text-sm"> Write Review </label>
        <vs-textarea label="Write Review" v-validate="{ required: true }" v-model="review.content" rows="3" name="content" />
        <span class="text-danger text-sm" v-show="errors.has('content')">{{ errors.first('content') }}</span>
        <div class="mt-3 flex justify-between items-center">
          <div>
            <star-rating :rtl="$vs.rtl" :rating="review.rating" @rating-selected="setRating" ></star-rating>
          </div>
          <!-- <div class="flex">
              <span class="mr-4">Show On Home</span>
            <vs-switch v-model="review.showOnHome" >
              <span slot="on">On</span>
              <span slot="off">Off</span>
            </vs-switch>
          </div> -->
        </div>
      </div>

      <div v-if="!review.is_real" class="vx-col mb-5 w-full">
        <vs-input label="Client Name *" placeholder="Reviewer's Name" name="reviewer_name" v-model="review.reviewer_name" class="w-full" />
      </div>

      <div v-if="!review.is_real" class="vx-col mb-5 w-full">
        <label class="text-sm"> Country </label>
        <v-select label="name"  name="country"
          v-model="review.country_id" :reduce="item => item.id" placeholder="--select country--" :closeOnSelect="true" :options="countries" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
      </div>

      <div v-if="!review.is_real" class="vx-col mb-5 w-full">
        <label class="text-sm">Review Date</label>
        <flat-pickr v-model="review.reviewed_at" :config="{ dateFormat: 'd F Y' }" class="w-full" placeholder="Publish Date" name="reviewed_at" />
      </div>

      <div  v-if="!review.is_real" class="vx-col mb-5 w-full">
        <vue-dropzone id="dropzone" ref="reviewImageDrop" :use-custom-slot="true" :options="dropzoneOptions">
          <div class="dz-message shadow text-center my-0 px-3 py-8 vx-card rounded needsclick" style="border: 1px dashed #aaa;">
              <i class="h1 text-primary mdi mdi-plus"></i>
              <h4>Upload Reviewer Image</h4>
              <span class="text-primary font-13">
                  Dimension should be <code>100px</code> x <code>100px</code><br> Maximum Size <code> 500kb</code>
              </span>
            </div>
        </vue-dropzone>
      </div>

      <div class="vx-col mb-5 w-full">
        <label class="text-sm">Write Response (if any)</label>
        <vs-textarea label="Response" v-model="review.response" rows="2" name="response" />
      </div>

      <div v-if="!review.is_real" class="vx-col mb-5 w-full">
        <div class="vx-col w-full">
          <label class="text-sm">Response Date</label>
          <flat-pickr v-model="review.responded_at" :config="{ dateFormat: 'd F Y' }" class="w-full" placeholder="Publish Date" />
        </div>
      </div>
    </div>
    <vs-button class="ml-auto mt-2" :disabled="!validateForm" @click="onUpdate ? updateReview(): createReview()" >{{onUpdate ? 'Update':'Add Review'}}</vs-button>
  </form>
</template>

<script>
import StarRating from 'vue-star-rating'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  components:{
    flatPickr,
    StarRating
  },
  data(){
    return{
      onUpdate: false,
      review: {
        rating: 0,
        content: "",
        reviewer_name: "",
        reviewed_at: null,
        response:"",
        responded_at:null,
        country_id:"",
      },
      dropzoneOptions: {
        url: "http//null",
        thumbnailWidth: 550,
        maxFilesize:5,
        maxFiles:1,
        autoProcessQueue:false,
        acceptedFiles: "image/*",
        headers: {
            "My-Awesome-Header": "header value",
        },
        previewTemplate: this.dropzoneTemplate(),
      },
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any()
    },
    countries(){
      return this.$store.state.countries
    },
  },
  methods:{
    setRating: function(rating) {
      this.review.rating = rating;
    },
    setUpEdit(review){
      this.onUpdate = true
      this.review = {
        id: review.id,
        is_real: review.is_real,
        rating: review.rating,
        content: review.content,
        reviewer_name: review.reviewer_name,
        reviewed_at: review.reviewed_at ? new Date(review.reviewed_at) : null,
        response: review.response,
        responded_at: review.responded_at ? new Date(review.responded_at) : null,
        product_id: review.product_id,
        country_id: review.country_id,
      }
    },
    createReview(){
      this.$validator.validateAll().then(result => {
        if (result) {
          let formData = new FormData()
          formData.append('data', JSON.stringify(this.review))
          if(this.collectFiles().length){
            formData.append('reviewer_image', this.collectFiles()[0])
          }
          this.$vs.loading({color: "#444", type:'sound'})
          this.$http.post('/reviews/create',formData)
          .then((response) => {
            this.$vs.loading.close()
            if(response.data.success){
              this.$store.commit("ADD_REVIEW", response.data.review)
              this.alertSuccess(response.data.success)
              this.resetAll()
            }else if(response.data.errors){
              response.data.errors.forEach((error) => { this.alertError(error)})
            }else{
              this.alertError(response.data.error)
            }
          }).catch((error) => { console.log(error) })
         }
      })
    },
    updateReview(){
      this.$validator.validateAll().then(result => {
        if (result) {
          let formData = new FormData()
          formData.append('data', JSON.stringify(this.review))
          if(!this.review.is_real && this.collectFiles().length){
            formData.append('reviewer_image', this.collectFiles()[0])
          }
          this.$vs.loading({color: "#444", type:'sound'})
          this.$http.post(`/reviews/${this.review.id}/update`,formData)
          .then((response) => {
            this.$vs.loading.close()
            if(response.data.success){
              this.$store.commit("UPDATE_REVIEW", response.data.review)
              this.alertSuccess(response.data.success)
              this.resetAll()
              this.$emit("closeform")
            }else if(response.data.errors){
              response.data.errors.forEach((error) => { this.alertError(error)})
            }else{
              this.alertError(response.data.error)
            }
          }).catch((error) => { console.log(error) })
        }
      })
    },
    collectFiles(){
      var arrafile = [];
      if(this.$refs.reviewImageDrop){
        arrafile = [...this.$refs.reviewImageDrop.getAcceptedFiles()];
      }
      return arrafile
    },
    resetAll() {
      this.$validator.reset()
      this.review = {
        rating: 0,
        content: "",
        reviewer_name: "",
        reviewed_at: null,
        response:"",
        responded_at: null,
        country_id:"",
      }
      if(this.$refs.reviewImageDrop){
        this.$refs.reviewImageDrop.removeAllFiles();
      }
    },
  }
}
</script>
