<template>
<div>
  <vs-table
        :max-items="descriptionItems[0]"
        pagination
        :data="reviews"
        description
        search
        :description-items="descriptionItems"
        description-title="Registries"
        description-connector="of"
        description-body="Pages">

        <template slot="header">
          <h5 class="flex items-center mb-2">
            <feather-icon icon="AwardIcon" class="w-5 h-5 mr-1"></feather-icon>
            <span> {{title}} </span>
          </h5>
        </template>
        <template slot="thead">
           <vs-th>
           Rating
          </vs-th>
          <vs-th>
            Image
          </vs-th>
          <vs-th>
           Client Name
          </vs-th>
          <vs-th>
           Country
          </vs-th>
          <vs-th v-if="isReal">
            Order
          </vs-th>
          <vs-th>
            Published at
          </vs-th>
          <vs-th>
            Action
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
            <vs-td :data="data[indextr].rating">
              <span class="flex"><feather-icon icon="StarIcon" class="w-5 h-5 text-warning mr-1"></feather-icon>
              {{data[indextr].rating}}</span>
            </vs-td>

            <template v-if="data[indextr].is_real">
              <vs-td :data="data[indextr].user.image">
                <img @click="$router.push('/users/'+data[indextr].user.id).catch(() => {})" class="w-12" :src="$store.state.domain+'/'+data[indextr].user.image" alt="Client image" />
              </vs-td>

              <vs-td :data="data[indextr].user.first_name">
                {{data[indextr].user.first_name | truncate(10)}}
              </vs-td>

              <vs-td :data="data[indextr].user.country.name">
                <span class="flex"><img style="height:15px;" class="mr-1" :src="$store.state.domain+'/images/country_flags/'+data[indextr].user.country.icon" alt="star" />
                {{data[indextr].user.country.name}}</span>
              </vs-td>
              <vs-td :data="data[indextr].order_id">
                <span class="text-info cursor-pointer" @click="$router.push('/orders/'+data[indextr].order_id).catch(() => {})">#DKO{{data[indextr].order_id}}</span>
              </vs-td>
              <vs-td :data="data[indextr].created_at">
                {{data[indextr].created_at | date_parse(true)}}
              </vs-td>
            </template>
            <template v-else>
              <vs-td :data="data[indextr].reviewer_image">
                <img class="w-12" :src="$store.state.domain+'/'+data[indextr].reviewer_image" alt="Client image" />
              </vs-td>
              <vs-td :data="data[indextr].reviewer_name">
                {{data[indextr].reviewer_name | truncate(10)}}
              </vs-td>
              <vs-td :data="data[indextr].country.name">
                <span class="flex"><img style="height:15px;" class="mr-1" :src="$store.state.domain+'/images/country_flags/'+data[indextr].country.icon" alt="star" />
                {{data[indextr].country.name}}</span>
              </vs-td>
              <vs-td :data="data[indextr].reviewed_at">
                {{data[indextr].reviewed_at | date_parse(true)}}
              </vs-td>
            </template>
            <vs-td class="whitespace-no-wrap">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="initEditReview(tr.id)" />
                <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteReview(tr.id)" />
            </vs-td>

            <template class="expand-user" slot="expand">
              <div class="con-expand-users w-full p-3">
                <div class="mb-3">
                  <h5>Review Details</h5>
                  <p class="mb-1">{{data[indextr].content}}</p>
                  <small class="d-block opacity-50">Published on: {{data[indextr].created_at | date}} </small>
                </div>
                <div class="pl-5" v-if="data[indextr].responder_id">
                  <h5 class="capitalize"> {{data[indextr].responder.username}} response</h5>
                  <p class="mb-1">{{data[indextr].response}}</p>
                  <small class="d-block opacity-50">Published on: {{data[indextr].responded_at | date}} </small>
                </div>
                 <div class="con-btns-user p-3" v-else>
                    <vs-button size="small" color="primary" @click="initResponse(tr.id)" class="mr-2"> Send Response</vs-button>
                  </div>
              </div>
            </template>
          </vs-tr>
        </template>
      </vs-table>
      <vs-popup class="holamundo" @close="close" title="Respond Review" :active.sync="popupResponse">
        <div class="mt-2">
          <label>You may respond to this review (This will be public)</label>
          <vs-textarea label="Share your response here" v-model="response" rows="1" />
        </div>
          <vs-button class="ml-auto mt-2" :disabled="!validateResponse" @click="sendResponse()" size="small">Share Response</vs-button>
      </vs-popup>
</div>
</template>

<script>
export default {
  name: "review-table",
  props:{
    isReal: {
      type: Number,
      default: 1
    },
    reviews: {
      type: Array,
      required: true
    }
  },
  data(){
    return {
      descriptionItems: [8,15,50],
      popupResponse:false,

      awaitingUpdate: null,
      awaitingDelete: null,
      response: ""
    }
  },
  computed:{
    title(){
      return this.isReal
        ? "List of  Reviews"
        : "List of  Flip Reviews"
    },
    validateResponse() {
      return this.response != ""
    }
  },
  methods:{
    initResponse(reviewId){
      this.awaitingUpdate = reviewId
      this.popupResponse = true
    },
    initEditReview(reviewId){
      this.$emit("onUpdate", reviewId)
    },
    sendResponse(){
      if(this.awaitingUpdate < 1){return}
      const formData = new FormData()
      formData.append('data', JSON.stringify({ response: this.response }))
      this.$http.post(`/reviews/${this.awaitingUpdate}/respond?return_review=true`,formData)
      .then((response)=>{
        if(response.data.success){
          this.alertSuccess(response.data.success)
          this.$store.commit("UPDATE_REVIEW", response.data.review)
          this.initializeAll()
        }else if(response.data.errors){
          response.data.errors.forEach((error) => { this.alertError(error)})
        }else{
          this.alertError(response.data.error)
        }
      }).catch((error)=> {console.log(error)})
    },
    deleteReview(reviewId){
      this.awaitingDelete = reviewId
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Are you sure?',
        text: 'The selected review will be deleted!',
        accept: this.acceptDelete
      })
    },
    acceptDelete(){
      if(!this.awaitingDelete){return}
      this.$http.delete(`/reviews/${this.awaitingDelete}/delete`)
      .then((response) =>{
        if(response.data.success){
          this.$store.commit('DELETE_REVIEW', this.awaitingDelete)
          this.alertSuccess(response.data.success)
          this.initializeAll();
        }else{
          this.alertError(response.data.error)
        }
      }).catch((error)=>{console.log(error)})
    },
    initializeAll(){
      this.awaitingUpdate = null
      this.awaitingDelete = null
      this.popupResponse = false
      this.response = ""
      this.$validator.reset()
    },
    close(){
      this.$vs.notify({
      title:'Notice',
      text:"You cancel an operation",
      color:'warning',
      iconPack: 'feather',
      icon:'icon-alert-circle',
      position:'bottom-right'})
      this.initializeAll()
    },
  }
}
</script>
