<template>
  <div>
    <div v-if="!isReal" class="mb-2 items-center">
      <vs-button class="ml-auto" @click="popupReviewForm = true;" size="small">Add New Review</vs-button>
    </div>
    <vx-card no-shadow>
      <!-- Save & Reset Button -->
      <review-table @onUpdate="initUpdate($event)" :reviews="reviews" :isReal="isReal"  />

    </vx-card>
    <vs-popup class="holamundo" @close="close"
     :title="onUpdate? 'Update Review': 'Add Review'"
     :active.sync="popupReviewForm">
        <div class="mt-2">
          <review-form @closeform="initializeAll()" ref="ReviewForm" />
        </div>
    </vs-popup>
  </div>
</template>
<script>

import ReviewTable from './components/ReviewTable.vue'
import ReviewForm from './components/ReviewForm.vue'

export default {
  components: {
    ReviewTable,
    ReviewForm
  },
  data() {
    return {
      popupReviewForm: false,
      onUpdate: false,
      isReal:1
    }
  },
  watch: {
    "$route.query":"refreshQueryStatus"
  },
  computed: {
    reviews() {
      return this.isReal
        ? this.$store.state.reviews.filter(r => r.is_real)
        : this.$store.state.reviews.filter(r => !r.is_real)
    },
  },
  methods:{
    refreshQueryStatus(){
        if(this.$route.query.is_real){
          this.isReal = parseInt(this.$route.query.is_real)
        }
    },
    initUpdate(reviewId){
      let review = this.reviews.find(rev => rev.id == reviewId);
      this.onUpdate = true
      this.$refs.ReviewForm.setUpEdit({...review});
      this.popupReviewForm = true
    },
    initializeAll(){
      this.popupReviewForm = false
      this.$refs.ReviewForm.resetAll()
    },
    close(){
      this.$vs.notify({
      title:'Notice',
      text:"You cancel an operation",
      color:'warning',
      iconPack: 'feather',
      icon:'icon-alert-circle',
      position:'bottom-right'})
      this.initializeAll()
    },
  },
  created(){
    this.$store.dispatch('fetchReviews')
  },
  mounted(){
    this.refreshQueryStatus()
  }
}
</script>

<style type="text/css">
  .vue-star-rating-star{
    height: 25px;
    width:25px;
  }
  .vs-con-textarea{
    margin-bottom: 0;
  }
</style>
